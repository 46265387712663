import React from "react";
import Column from "~/column";
import Container from "~/container";
import Layout from "~/layout";
import SEO from "~/seo";

const PrivacyPage = () => (
    <Layout>
        <Container spacing={true}>
            <Column className="max-w-screen-lg mx-auto">
                <h1>Datenschutz</h1>

                <p>
                    Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener
                    Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise
                    Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit
                    möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                    ausdrückliche Zustimmung nicht an Dritte weitergegeben.
                </p>

                <p>
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der
                    Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser
                    Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                </p>

                <p>
                    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten
                    durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und
                    Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber
                    der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der
                    unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
                </p>
            </Column>
        </Container>
    </Layout>
);

export const Head = () => (
    <SEO
        title="Datenschutz"
        description="Informationen über den Umgang mit Daten, die bei der Nutzung dieser Website erhoben werden."
    />
);

export default PrivacyPage;
